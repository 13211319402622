<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-data-table
        :headers="tableHeaders"
        :mobile="$vuetify.display.xs"
        :items="sources"
        :items-per-page="-1"
        :row-props="{ class: 'source-row text-body-2' }"
        :group-by="groupBy"
        :sort-by="[
          { key: 'stock', order: 'asc' },
          { key: 'price.resellPrice', order: 'asc' }
        ]"
        :custom-key-sort="{ stock: statusCompare }"
        :density="$vuetify.display.xs ? 'compact' : 'default'"
      >
        <template #bottom />

        <template #[`header.stock`]="{ column }">
          <div class="d-flex flex-row jusitify-center cursor-pointer" @click="toggleGrouping">
            <span>{{ column.title }}</span>
            <v-icon v-show="!groupProductSources" size="18" :icon="mdiLockOpenVariantOutline" />
            <v-icon v-show="groupProductSources" size="18" :icon="mdiLockOutline" />
          </div>
        </template>

        <template #[`header.addToCart`]="{}">
          <realtime-prices-indicator
            v-if="$vuetify.display.smAndUp"
            :loading="loadingRealtimePrices"
          />
        </template>

        <template #[`group-header`]="{ item, isGroupOpen, toggleGroup }">
          <tr
            :ref="
              () => {
                if (!isGroupOpen(item)) toggleGroup(item)
              }
            "
            class="bg-grey-lighten-4"
          >
            <td :colspan="tableHeaders.length + 1">
              <sf-heading>{{ $t('stockStatus.' + item.value) }}</sf-heading>
            </td>
          </tr>
        </template>

        <template #[`item.supplierName`]="{ item }">
          <span>
            {{ item.supplierName }}
          </span>
          <sf-condition-chip
            v-if="item.condition && item.condition !== Condition.NEW"
            class="ml-1"
            :condition="item.condition"
          />
        </template>

        <template #[`item.stock.count`]="{ item }">
          <span
            v-if="item.stock && item.stock.count > 0"
            :class="[{ 'text-grey': loadingRealtimePrices }]"
          >
            {{ item.stock.count }}
          </span>
          <span
            v-if="item.stock && item.stock.count === 0"
            :class="[{ 'text-grey': loadingRealtimePrices }]"
          >
            -
          </span>
        </template>
        <template #[`item.stock`]="{ item }">
          <product-source-stock
            v-if="item.stock"
            :stock="item.stock"
            :loading-realtime-prices="loadingRealtimePrices"
          />
        </template>

        <template v-if="hasPurchasePrice" #[`item.price.purchasePrice`]="{ item }">
          <span v-if="item.price" :class="[{ 'text-grey': loadingRealtimePrices }]">
            {{ formatMoney(item.price.purchasePrice) }}
          </span>
        </template>
        <template #[`item.price.resellPrice`]="{ item }">
          <span v-if="item.price" :class="[{ 'text-grey': loadingRealtimePrices }]">
            {{ formatMoney(item.price.resellPrice, true) }}
          </span>
          &nbsp;
          <span v-if="item.price && item.price.subscriptionModel" class="text-medium-emphasis">
            {{
              $t('price.priceBillingPeriodShort', [
                formatDurationShort(
                  item.price.subscriptionModel.billingPeriod!,
                  item.price.subscriptionModel.billingPeriodUnit!
                )
              ])
            }}
          </span>
          <packaging-info
            v-if="item.price && item.price.packagingModel"
            class="text-medium-emphasis"
            :packaging-model="item.price.packagingModel"
          />
        </template>

        <template #[`item.addToCart`]="{ item }">
          <div class="d-flex my-1 justify-end align-center">
            <v-icon v-if="item.scales.length > 0" class="mr-2" :icon="mdiElevationDecline" />
            <quantity-stepper
              v-if="item.stock && item.price && item.price.resellPrice.value > 0"
              class="mr-1"
              :quantity="quantity"
              :stock="item.stock.count"
              :packaging="item.price.packagingModel"
              @change-quantity="changeQuantity"
              @focus="setUpdatingQuantity(true)"
              @blur="setUpdatingQuantity(false)"
            />
            <cart-button
              :is-icon="true"
              :is-request="item.price && item.price.resellPrice.value <= 0"
              :product-id="productId"
              :quantity="quantity"
              :supplier-row-id="item.id"
              :on-click-event="addToCartEvent"
              :updating-quantity
            />
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import PackagingInfo from '@/components/PackagingInfo.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import RealtimePricesIndicator from '@/components/RealtimePricesIndicator.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfConditionChip from '@/components/chips/SfConditionChip.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { Condition, SupplierRow } from '@/generatedTypes'
import { moneyCompare, numberCompare, statusCompare, stringCompare } from '@/helpers'
import ProductSourceStock from '@/modules/product/components/ProductSourceStock.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import useProductQuantity from '@/modules/product/useProductQuantity'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import { DataTableHeader } from '@/types'
import { formatDurationShort } from '@/utils/timeFormatHelpers'
import { mdiCheck, mdiElevationDecline, mdiLockOpenVariantOutline, mdiLockOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ProductSources',
  components: {
    CartButton,
    PackagingInfo,
    ProductSourceStock,
    QuantityStepper,
    RealtimePricesIndicator,
    SfHeading,
    SfConditionChip
  },
  props: {
    sources: {
      type: Array as PropType<SupplierRow[]>,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    loadingRealtimePrices: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()

    const groupProductSources = ref(true)

    const groupBy = computed(() => {
      return groupProductSources.value ? [{ key: 'stock.status' }] : []
    })

    const hasPurchasePrice = computed(() =>
      props.sources.some((source) => source.price && source.price.purchasePrice)
    )
    const hasSupplierNumbers = computed(() => props.sources.some((source) => source.supplierPid))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableHeaders: DataTableHeader<any>[] = [
      {
        title: '',
        key: 'data-table-group',
        width: '0px',
        align: 'center',
        sortable: false,
        headerProps: {
          class: 'pa-0',
          style: 'min-height: 0px !important'
        },
        cellProps: {
          class: 'pa-0',
          style: 'min-height: 0px !important'
        }
      },
      {
        title: t('supplier').toString(),
        value: 'supplierName',
        align: 'start',
        sortable: true,
        sort: stringCompare
      },
      {
        title: t('stock').toString(),
        value: 'stock.count',
        align: 'end',
        sortable: true,
        sort: numberCompare
      },
      {
        title: t('availability').toString(),
        value: 'stock',
        align: 'start',
        sortable: false
      },
      {
        title: t('price.price').toString(),
        value: 'price.resellPrice',
        align: 'start',
        sortable: true,
        sort: moneyCompare
      },
      {
        title: '',
        value: 'addToCart',
        align: 'end',
        sortable: false
      }
    ]

    if (hasPurchasePrice.value) {
      tableHeaders.splice(4, 0, {
        title: t('price.pricePurchase').toString(),
        value: 'price.purchasePrice',
        align: 'start',
        sortable: true,
        sort: moneyCompare
      })
    }

    if (hasSupplierNumbers.value) {
      tableHeaders.splice(2, 0, {
        title: t('supplierPid').toString(),
        value: 'supplierPid',
        align: 'start',
        sortable: false
      })
    }

    const toggleGrouping = () => {
      groupProductSources.value = !groupProductSources.value
    }

    const { quantity, updatingQuantity } = storeToRefs(useProductQuantity())

    const changeQuantity = (quantity: number) => {
      useProductQuantity().updateQuantity(quantity, props.productId)
    }

    const setUpdatingQuantity = (value: boolean) => {
      useProductQuantity().setUpdatingQuantity(value)
    }

    return {
      groupProductSources,
      hasPurchasePrice,
      tableHeaders,
      addToCartEvent: ProductPageEvent.AddToCart.Source,
      Condition,
      groupBy,
      changeQuantity,
      toggleGrouping,
      formatDurationShort,
      formatMoney,
      getStatusColor,
      statusCompare,
      setUpdatingQuantity,
      updatingQuantity,
      quantity,
      mdiElevationDecline,
      mdiLockOpenVariantOutline,
      mdiLockOutline,
      mdiCheck
    }
  }
})
</script>
