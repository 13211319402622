<template>
  <sf-checkbox
    v-model="checked"
    :rules="[rules.requiredRule]"
    @update:model-value="$emit('input', checked)"
  >
    <template #label>
      <span @click.stop v-html="customTerms" />
    </template>
  </sf-checkbox>
</template>

<script lang="ts">
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import rules from '@/rules'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CustomTermsCheckbox',
  components: { SfCheckbox },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    customTerms: {
      type: String,
      required: true
    }
  },
  emits: ['input', 'update:modelValue'],
  setup(props) {
    const checked = ref(props.modelValue)

    return {
      checked,
      rules
    }
  }
})
</script>
