<template>
  <div>
    <!-- price -->
    <v-row dense>
      <v-col class="d-flex flex-column text-primary my-4" cols="auto">
        <div class="d-flex align-center ga-1">
          <span
            ref="price"
            class="text-h4 font-weight-bold"
            :class="[{ 'text-grey': loadingRealtimePrices }]"
          >
            {{ formatMoney(price ? price.resellPrice : undefined, true) }}
          </span>
          <v-progress-circular
            v-if="loadingRealtimePrices"
            indeterminate
            size="24"
            color="primary"
            width="2"
          />
        </div>
        <div v-if="price && price.subscriptionModel" class="text-body-1">
          <billing-period ref="billing" :subscription-model="price.subscriptionModel" />
          -
          <subscription-period ref="subscription" :subscription-model="price.subscriptionModel" />
        </div>
        <packaging-info
          v-if="price && price.packagingModel"
          class="text-body-1"
          :packaging-model="price.packagingModel"
        />
      </v-col>
      <v-col
        v-if="condition && condition !== Condition.NEW"
        class="d-flex align-center"
        cols="auto"
      >
        <sf-condition-chip :condition="condition" />
      </v-col>
    </v-row>

    <!-- stock + add to cart -->
    <v-row dense>
      <v-col cols="12" class="d-flex flex-column">
        <div
          v-if="stock"
          ref="stock"
          :class="
            (loadingRealtimePrices ? 'text-grey' : 'text-' + getStatusColor(stock.status)) +
            ' font-weight-bold mb-1'
          "
        >
          <span v-if="stock.count > 0" ref="stock-count">{{ stock.count }}</span>
          <span ref="stock-status">{{ ' ' + $t('stockStatus.' + stock.status) }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <quantity-stepper
            v-if="stock"
            ref="quantity-stepper"
            class="mr-1 mb-1"
            :quantity="quantity"
            :stock="stock.count"
            :packaging="price ? price.packagingModel : undefined"
            @change-quantity="changeQuantity"
            @focus="setUpdatingQuantity(true)"
            @blur="setUpdatingQuantity(false)"
          />
          <cart-button
            :is-request="isRequest"
            :product-id="productId"
            :quantity="quantity"
            :on-click-event="addToCartEvent"
            :supplier-row-id="price ? price.supplierRowId : undefined"
            :updating-quantity
          />
        </div>
      </v-col>
    </v-row>

    <!-- uvp + hek -->
    <v-row v-if="manufacturerSuggestedRetailPrice || (price && price.purchasePrice)" dense>
      <v-col cols="12" class="d-flex flex-wrap text-body-1 text-medium-emphasis">
        <div v-if="manufacturerSuggestedRetailPrice" class="mr-3">
          <span class="mr-1">
            {{ $t('price.manufacturerSuggestedRetailPrice') }}
          </span>
          <span>{{ formatMoney(manufacturerSuggestedRetailPrice) }}</span>
        </div>
        <div v-if="price && price.purchasePrice">
          <span class="mr-1">{{ $t('price.pricePurchase') }}</span>
          <span>{{ formatMoney(price.purchasePrice) }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="(price && price.packagingModel) || suppliers.some((e) => e.scales.length > 0)"
      dense
    >
      <v-col cols="12" class="d-flex flex-wrap flex-gap">
        <product-packaging v-if="price && price.packagingModel" :packaging="price.packagingModel" />
        <product-graduation
          v-if="suppliers.some((e) => e.scales.length > 0)"
          :suppliers="suppliers"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfConditionChip from '@/components/chips/SfConditionChip.vue'
import useCart from '@/modules/cart/useCart'
import ProductGraduation from '@/modules/product/components/ProductGraduation.vue'
import ProductPackaging from '@/modules/product/components/ProductPackaging.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { Condition, Money, SimplePrice, SimpleStock, SupplierRow } from '@/generatedTypes'
import useProductQuantity from '@/modules/product/useProductQuantity'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductPrice',
  components: {
    BillingPeriod,
    CartButton,
    PackagingInfo,
    ProductGraduation,
    ProductPackaging,
    QuantityStepper,
    SfConditionChip,
    SubscriptionPeriod
  },
  props: {
    addToCartEvent: {
      type: Object as PropType<AddToCartButtonEvents>,
      default: undefined
    },
    manufacturerSuggestedRetailPrice: {
      type: Object as PropType<Money | undefined>,
      default: undefined
    },
    price: {
      type: Object as PropType<SimplePrice | undefined>,
      default: undefined
    },
    stock: {
      type: Object as PropType<SimpleStock | undefined>,
      default: undefined
    },
    productId: {
      type: Number,
      required: true
    },
    suppliers: {
      type: Array as PropType<SupplierRow[]>,
      required: true
    },
    condition: {
      type: String as PropType<Condition>,
      default: undefined
    },
    loadingRealtimePrices: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { loading } = storeToRefs(useCart())
    const { quantity, updatingQuantity } = storeToRefs(useProductQuantity())
    const isRequest = computed(() => !props.price || props.price.resellPrice.value <= 0)

    const changeQuantity = (quantity: number) => {
      useProductQuantity().updateQuantity(quantity, props.productId)
    }

    const setUpdatingQuantity = (value: boolean) => {
      useProductQuantity().setUpdatingQuantity(value)
    }

    return {
      loading,
      quantity,
      isRequest,
      Condition,
      updatingQuantity,
      changeQuantity,
      setUpdatingQuantity,
      formatMoney,
      getStatusColor
    }
  }
})
</script>
