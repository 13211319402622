import { Request } from '@/api'
import { ViewToggle } from '@/types'
import { createEmptyAddress } from '@/modules/address/helpers'
import useOwnUser from '@/modules/user/useOwnUser'
import {
  ApprovalRequest,
  Checkout,
  OrderPostDTO,
  OrderStatus,
  ProposeOrderPostDTO,
  ContactData,
  OrderingConditions,
  FilterIdentifier
} from '@/generatedTypes'
import { OrdersView, PreparedOrder } from '@/modules/order/types'
import useOrders from '@/modules/order/useOrders'
import { mdiNoteCheck, mdiViewDashboard, mdiViewList } from '@mdi/js'

const applyFilters = (config: Request, filters: FilterIdentifier[]): Request => {
  if (Array.isArray(filters) && filters.length) {
    const allSelectedValues = filters.flatMap((identifier) => identifier.values)

    // Archived
    const archived =
      allSelectedValues.filter((value) => value.term.startsWith('ARCARCHIVED')).length > 0
    const notarchived =
      allSelectedValues.filter((value) => value.term.startsWith('ARCNOTARCHIVED')).length > 0
    if (archived != notarchived) {
      config.params.archived = archived
    }

    // Status
    const statuses = allSelectedValues.filter((value) => value.term.startsWith('DST'))
    if (statuses.length > 0) {
      config.params.status = statuses.map((value) => value.term.substr(3)).join(',')
    }

    // Creator
    const creators = allSelectedValues.filter((value) => value.term.startsWith('EMP'))
    if (creators.length > 0) {
      config.params.creators = creators.map((value) => value.term).join(',')
    }
  }

  return config
}

const errorStatuses = [
  OrderStatus.ERROR,
  OrderStatus.REJECTED,
  OrderStatus.CANCELED,
  OrderStatus.SENDING_ERROR,
  OrderStatus.PORTAL_APPROVAL_DENIED
]

const deleteableStatuses = [OrderStatus.PREPARING, OrderStatus.ORDERED_ELSEWHERE]

const isErrorStatus = (status: OrderStatus): boolean => {
  return errorStatuses.includes(status)
}

const getStatusColor = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.ARRIVED_WARNING:
    case OrderStatus.BLOCKED_BYLIMIT:
    case OrderStatus.PAYMENT_AWAITING:
      return 'orange-darken-2'
    case OrderStatus.ERROR:
    case OrderStatus.REJECTED:
    case OrderStatus.PORTAL_APPROVAL_DENIED:
    case OrderStatus.SENDING_ERROR:
    case OrderStatus.CANCELED:
    case OrderStatus.BLOCKED:
      return 'red'
    default:
      return 'green'
  }
}

const formatDistributors = (suppliers: string[]): string => {
  let title = ''
  suppliers.forEach((supplier, key) => {
    title += supplier
    if (key !== suppliers.length - 1) {
      title += ', '
    }
  })

  return title
}

const getDefaultBillingContactData = (): ContactData => {
  const ownUser = useOwnUser().ownUser
  return {
    firstName: ownUser.firstName,
    lastName: ownUser.lastName,
    email: ownUser.email,
    phone: ownUser.phone,
    phoneMobile: ownUser.phoneMobile
  }
}

const createPreparedOrder = (
  checkout: Checkout,
  orderingConditions: OrderingConditions
): PreparedOrder => {
  return {
    orderNumber: checkout.orderNumber ?? undefined,
    billingAddress: checkout.billingAddress.default ?? createEmptyAddress(),
    billingContactData: getDefaultBillingContactData(),
    deliveryAddress: checkout.deliveryAddress.default ?? createEmptyAddress(),
    deliveryContactData: undefined,
    usePartialShipment: orderingConditions.isPartialShipmentEnabled ? true : undefined
  }
}

const createOrderPostDTO = (preparedOrder: PreparedOrder, timestamp: string): OrderPostDTO => {
  return { timestamp, ...preparedOrder }
}

const createProposedOrderPostDTO = (
  preparedOrder: PreparedOrder,
  timestamp: string,
  commentToApprovers: string
): ProposeOrderPostDTO => {
  return { timestamp, commentToApprovers, ...preparedOrder }
}

const getOrdersViews = (): ViewToggle<OrdersView>[] => [
  {
    name: OrdersView.LIST,
    icon: mdiViewList,
    restriction: () => true,
    showOnMobile: true
  },
  {
    name: OrdersView.GRID,
    icon: mdiViewDashboard,
    restriction: () => true,
    showOnMobile: false
  },
  {
    name: OrdersView.APPROVALS,
    icon: mdiNoteCheck,
    label: 'orderApproval.approvableOrders',
    restriction: useOwnUser().isApprover,
    showOnMobile: true,
    count: {
      loadFunction: useOrders().checkApprovableOrders,
      getter: useOrders().hasApprovableOrders
    }
  }
]

const isOrderApprovableByMe = (approvalRequest: ApprovalRequest): boolean => {
  const ownUser = useOwnUser().ownUser

  return approvalRequest.assignees.find((assignee) => assignee.id === ownUser.id) != undefined
}

export {
  applyFilters,
  createOrderPostDTO,
  createPreparedOrder,
  createProposedOrderPostDTO,
  errorStatuses,
  deleteableStatuses,
  formatDistributors,
  getOrdersViews,
  getStatusColor,
  isErrorStatus,
  isOrderApprovableByMe
}
