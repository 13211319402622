import { ApiError, handleApiError } from '@/handleApiError'
import { AddressType } from '@/modules/address/types'
import useAddressValidation from '@/modules/address/useAddressValidation'
import {
  Cart,
  SupplierCart,
  ShopAddress,
  ContactData,
  Checkout,
  OrderStatus
} from '@/generatedTypes'
import useCartCount from '@/modules/cart/useCartCount'
import helpers from '@/modules/message/helpers'
import api from '@/modules/order/api'
import cartApi from '@/modules/cart/api'
import {
  createOrderPostDTO,
  createPreparedOrder,
  createProposedOrderPostDTO
} from '@/modules/order/helpers'
import { CHECKOUT_SESSION_TIME_MILLISECONDS, PreparedOrder } from '@/modules/order/types'
import useOrderingConditions from '@/modules/portalSettings/useOrderingConditions'
import useQuote from '@/modules/quote/useQuote'
import i18n from '@/plugins/i18n'
import router from '@/router'
import { KeyValue } from '@/types'
import { defineStore } from 'pinia'
import { Ref } from 'vue'

interface CheckoutState {
  checkout: Checkout | undefined
  cart: Cart | SupplierCart | undefined
  preparedOrder: PreparedOrder | undefined
  paymentMethod: string | undefined
  loading: boolean
  cartLoading: boolean
  sending: boolean
  cartOutdated: boolean
  orderNumberError: string | undefined
  commentsValid: KeyValue[]
  checkoutTerms: string | undefined
  sessionExpired: boolean
  sessionExpiredTimer: ReturnType<typeof setTimeout> | undefined
}

const initialState = (): CheckoutState => {
  return {
    checkout: undefined,
    cart: undefined,
    preparedOrder: undefined,
    paymentMethod: undefined,
    loading: false,
    cartLoading: false,
    sending: false,
    cartOutdated: false,
    orderNumberError: undefined,
    commentsValid: [],
    checkoutTerms: undefined,
    sessionExpired: false,
    sessionExpiredTimer: undefined
  }
}

const loadCartFinally = (state: CheckoutState) => {
  state.sessionExpired = false
  clearTimeout(state.sessionExpiredTimer)
  useCheckout().cartOutdated = false
  state.cartLoading = false
  state.sessionExpiredTimer = setTimeout(
    () => (state.sessionExpired = true),
    CHECKOUT_SESSION_TIME_MILLISECONDS
  )
}

const useCheckout = defineStore('checkout', {
  state: () => initialState(),
  getters: {
    allCommentsValid(state) {
      return state.commentsValid.every((entry) => {
        return entry.value
      })
    },
    isCommentValid: (state) => {
      return (cartItemId: string) =>
        state.commentsValid.find((entry) => entry.key === cartItemId)?.value
    },
    getCartTimestamp: (state) => {
      return () => state.cart?.timestamp ?? ''
    }
  },
  actions: {
    concatePaymentComment(comment: string | undefined) {
      return (
        (comment ? comment + '\n\n' : '') +
        i18n.global.t('paymentMethod') +
        ': ' +
        i18n.global.t('mobexPaymentMethod.' + this.paymentMethod)
      )
    },
    addCommentValid(id: string, valid: boolean) {
      const entry = this.commentsValid.find((entry) => entry.key === id)
      if (entry) {
        entry.value = valid
      } else {
        this.commentsValid.push({ key: id, value: valid })
      }
    },
    async loadCheckoutTerms() {
      const locale = i18n.global.locale
        ? (i18n.global.locale as unknown as Ref<string>).value
        : navigator.language

      return api
        .loadCheckoutTerms(locale)
        .then(({ data }) => {
          this.checkoutTerms = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'FULLPAGE'
          })
        })
    },
    async loadCheckout(supplierCartId?: string) {
      this.sessionExpired = false
      this.loading = true
      this.commentsValid = []
      this.orderNumberError = undefined
      this.loadCheckoutTerms()

      return api
        .loadCheckout(supplierCartId)
        .then(async ({ data }) => {
          this.checkout = data
          this.paymentMethod = undefined
          this.preparedOrder = createPreparedOrder(data, useOrderingConditions().orderingConditions)

          useAddressValidation().validateAddress(
            this.preparedOrder?.billingAddress,
            AddressType.billing
          )
          useAddressValidation().validateAddress(
            this.preparedOrder?.deliveryAddress,
            AddressType.delivery
          )

          await this.loadCart(supplierCartId)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'FULLPAGE'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async loadCart(supplierCartId?: string, refresh = false) {
      this.cartLoading = true

      if (supplierCartId) {
        return cartApi
          .loadSupplierCart(supplierCartId, true)
          .then(({ data }) => {
            this.cart = data
            if (refresh) {
              helpers.reportSuccess('success.cartRefreshed')
            }
          })
          .catch((error: ApiError) => {
            handleApiError(
              error,
              {
                appearance: 'FULLPAGE'
              },
              { errorCode: 404, logError: false }
            )
          })
          .finally(() => {
            loadCartFinally(this)
          })
      } else {
        return cartApi
          .loadCart(true)
          .then(({ data }) => {
            this.cart = data
            if (refresh) {
              helpers.reportSuccess('success.cartRefreshed')
            }
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              appearance: 'FULLPAGE'
            })
          })
          .finally(() => {
            loadCartFinally(this)
          })
      }
    },
    async loadCheckoutForQuote(quoteId: string) {
      this.loading = true
      this.commentsValid = []
      this.loadCheckoutTerms()

      return api
        .loadCheckoutForQuote(quoteId)
        .then(async ({ data }) => {
          this.checkout = data
          this.paymentMethod = undefined
          this.preparedOrder = createPreparedOrder(data, useOrderingConditions().orderingConditions)

          useAddressValidation().validateAddress(
            this.preparedOrder?.billingAddress,
            AddressType.billing
          )
          useAddressValidation().validateAddress(
            this.preparedOrder?.deliveryAddress,
            AddressType.delivery
          )

          await useQuote().getQuote(quoteId)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'FULLPAGE'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async validateOrderNumber(orderNumber: string) {
      return await api
        .validateOrderNumber(orderNumber)
        .then(({ data }) => {
          if (data) {
            this.orderNumberError = undefined
          } else {
            this.orderNumberError = i18n.global
              .t('order.orderNumberDuplicate', [orderNumber])
              .toString()
          }
        })
        .catch(handleApiError)
    },
    async sendOrder(supplierCartId?: string) {
      this.sending = true

      if (this.preparedOrder) {
        if (this.paymentMethod) {
          this.preparedOrder.orderComment = this.concatePaymentComment(
            this.preparedOrder.orderComment
          )
          delete this['paymentMethod']
        }

        return api
          .sendOrder(
            createOrderPostDTO(this.preparedOrder, this.getCartTimestamp()),
            supplierCartId
          )
          .then(async ({ data }) => {
            await router.push({
              name: 'OrderDetails',
              params: { id: data.id },
              query: { orderStatus: OrderStatus.SENT }
            })
          })
          .catch((error: ApiError) => {
            if (error.code == 409) {
              this.cartOutdated = true
            } else {
              handleApiError(error, undefined, {
                errorCode: 403,
                logError: false
              })
            }
          })
          .finally(() => {
            useCartCount().loadCartCount()
            this.sending = false
          })
      }
    },
    async sendOrderAndPay(supplierCartId?: string) {
      this.sending = true

      if (this.preparedOrder) {
        return api
          .sendOrderAndPay(
            createOrderPostDTO(this.preparedOrder, this.getCartTimestamp()),
            supplierCartId
          )
          .then(({ data }) => {
            if (data) {
              window.location.href = data
            }
          })
          .catch((error: ApiError) => {
            if (error.code == 409) {
              this.cartOutdated = true
            } else {
              handleApiError(
                error,
                undefined,
                {
                  // connection failure to sofort
                  errorCode: 502,
                  customMessageKey: 'order.paymentServiceConnectionException'
                },
                {
                  errorCode: 403,
                  logError: false
                }
              )
            }
          })
          .finally(() => {
            useCartCount().loadCartCount()
            this.sending = false
          })
      }
    },
    async sendQuoteOrder(quoteId: string, timestamp: string) {
      this.sending = true
      if (this.preparedOrder) {
        if (this.paymentMethod) {
          this.preparedOrder.orderComment = this.concatePaymentComment(
            this.preparedOrder.orderComment
          )
          delete this['paymentMethod']
        }
        const orderPostDTO = createOrderPostDTO(this.preparedOrder, timestamp)
        orderPostDTO.timestamp = useQuote().getVersion()

        return api
          .sendQuoteOrder(orderPostDTO, quoteId)
          .then(({ data }) => {
            router.push({
              name: 'OrderDetails',
              params: { id: data.id },
              query: { orderStatus: OrderStatus.SENT }
            })
          })
          .catch((error: ApiError) => {
            if (error.code == 409) {
              this.cartOutdated = true
            } else {
              handleApiError(error, undefined, {
                errorCode: 403,
                logError: false
              })
            }
          })
          .finally(() => {
            this.sending = false
          })
      }
    },
    async requestOrder(commentToApprovers: string, supplierCartId?: string) {
      if (!this.preparedOrder) {
        if (!this.checkout) {
          return
        }
        this.preparedOrder = createPreparedOrder(
          this.checkout,
          useOrderingConditions().orderingConditions
        )
      }
      this.sending = true

      if (this.paymentMethod && this.preparedOrder) {
        this.preparedOrder.orderComment = this.concatePaymentComment(
          this.preparedOrder?.orderComment
        )
        delete this['paymentMethod']
      }

      return api
        .requestOrder(
          createProposedOrderPostDTO(
            this.preparedOrder,
            this.getCartTimestamp(),
            commentToApprovers
          ),
          supplierCartId
        )
        .then(({ data }) => {
          helpers.reportSuccess('order.orderRequested')
          router.push({
            name: 'OrderDetails',
            params: { id: data.id }
          })
        })
        .catch((error: ApiError) => {
          if (error.code == 409) {
            this.cartOutdated = true
          } else {
            handleApiError(error)
          }
        })
        .finally(() => {
          useCartCount().loadCartCount()
          this.sending = false
        })
    },
    async requestQuoteOrder(commentToApprovers: string, quoteId: string, timestamp: string) {
      if (!this.preparedOrder) {
        if (!this.checkout) {
          return
        }
        this.preparedOrder = createPreparedOrder(
          this.checkout,
          useOrderingConditions().orderingConditions
        )
      }
      this.sending = true

      if (this.paymentMethod && this.preparedOrder) {
        this.preparedOrder.orderComment = this.concatePaymentComment(
          this.preparedOrder.orderComment
        )
        delete this['paymentMethod']
      }

      return api
        .requestQuoteOrder(
          createProposedOrderPostDTO(this.preparedOrder, timestamp, commentToApprovers),
          quoteId
        )
        .then(({ data }) => {
          helpers.reportSuccess('order.orderRequested')
          router.push({
            name: 'OrderDetails',
            params: { id: data.id }
          })
        })
        .catch((error: ApiError) => {
          if (error.code == 409) {
            this.cartOutdated = true
          } else {
            handleApiError(error)
          }
        })
        .finally(() => {
          this.sending = false
        })
    },
    setPartialShipmentSelection(selection: boolean) {
      if (this.preparedOrder) {
        this.preparedOrder.usePartialShipment = selection
      }
    },
    setOrderNumber(orderNumber: string) {
      if (this.preparedOrder) {
        this.preparedOrder.orderNumber = orderNumber
      }
    },
    setCustomerOrderId(customerOrderId: string) {
      if (this.preparedOrder) {
        this.preparedOrder.customerOrderId = customerOrderId
      }
    },
    setOrderComment(comment: string) {
      if (this.preparedOrder) {
        this.preparedOrder.orderComment = comment
      }
    },
    setBillingAddress(address: ShopAddress) {
      if (this.preparedOrder) {
        this.preparedOrder.billingAddress = address
        useAddressValidation().validateAddress(address, AddressType.billing)
      }
    },
    setDeliveryAddress(address: ShopAddress) {
      if (this.preparedOrder) {
        this.preparedOrder.deliveryAddress = address
        useAddressValidation().validateAddress(address, AddressType.delivery)
      }
    },
    setBillingContactData(contactData?: ContactData) {
      if (this.preparedOrder) {
        this.preparedOrder.billingContactData = contactData
      }
    },
    setDeliveryContactData(contactData?: ContactData) {
      if (this.preparedOrder) {
        this.preparedOrder.deliveryContactData = contactData
      }
    }
  }
})

export default useCheckout
